import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'

function TermsConditions() {
    return (
        <>
            <div class="wrapper">
                <Header />
                <section class="page-title-layout1 page-title-light bg-overlay bg-parallax text-center bg-img" style={{ backgroundImage: `url("assets/images/page-titles/1.jpg")`, backgroundSize: "cover", backgroundPosition: "center center" }}>
                    {/* <div class="bg-img"><img src="assets/images/page-titles/1.jpg" alt="background"/></div> */}
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 offset-xl-3">
                                <h1 class="pagetitle__heading">Terms and Conditions</h1>
                                <nav>
                                    <ol class="breadcrumb justify-content-center mb-0">
                                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                                        {/* <li class="breadcrumb-item"><a href="about-us.html">Company</a></li> */}
                                        <li class="breadcrumb-item active" aria-current="page">Terms and Conditions</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                    <h1>Terms and Conditions</h1>
<p>Terms and Conditions of "Rocket Paisa" Wallet Services provided by CJL FINTECH SOLUTIONS, having its office at A-1 Parivahan Nagar, khatipura Road, Jaipur-302012, Rajasthan.</p>
<ul>
<li>Stay updated by regular checking your registered email for new updating offer &amp; features. Our new and updated schemes /polices/circulars will always updated on our website https://www.rocketpaisa.in</li>
<li>Do not share your unique User ID and password to anyone.</li>
<li>Agent can only load Customer Rocket Paisa Wallet using his / her Agent Account.</li>
<li>Rocket Paisa reserves the right to do the due diligence / get police verification / get scrutiny of KYC documents submitted at the time of agent registration.</li>
<li>Agent Account will be opened after completion of due diligence / police verification / scrutiny of KYC documents process.</li>
<li>Please always keep changing /reset your password periodically.</li>
<li>Phishing through Internet is a fraudulent attempt, usually made through social media sites like face book, email, and phone calls, SMS etc seeking your personal and confidential information. Never respond to such social sites like facebook post / web address/email/SMS or phone call. If it happens report on help.rocketpaisa@gmail.com immediately.</li>
<li>Please keep original software/ hardware with valid license keys in your machines like Computer/Mobile. It is only your responsibility to maintain and update all software and hardware. The company is not responsible for any misshaping due to any pirated software or hardware.</li>
<li>If you and any person attached with you, will find guilty of any criminal or civil illegal activities or involved in any other illegal activities at any time currently or in near future or Any FIR will find against you. The Company shall suspend your account with immediate effect without any information and discussion.</li>
<li>The Company having all rights to decide or having sole desecration to decide about the termination of the account for a period based upon the performance of the account holder.</li>
<li>If any Problem persist related to your account / any network Related Problem the resolution will be given only through remote login or by telecommunication between the office times(9.00AM to 6.00PM) except Sunday and national holidays.</li>
<li>If any Franchisee communicates with telephonic conversation to the company the total cost of the communication will be bear by him only. The company is not responsible or bears this communication cost.</li>
<li>The Company also having rights to show its name and logo in the footer of software's main screen or everywhere whichever it is necessary.</li>
<li>If any type of suspicious activities or negligence related to the terms &amp; conditions found then Rocket Paisa reserve rights to suspend the services and termination of user account/login panel at any time without prior notice or without any explanation and any reward/ award/ royalty/ cash back/ any other benefits will be withdrawn with immediate effect with the imposition of strict penalty and legal action can be taken.</li>
<li>Rocket Paisa reserve all rights related to modification and discontinuous of any Scheme/ Plan/ Offer at any time without prior notice and any explanation.</li>
<li>The all above terms and conditions are based on the company's policy, procedures and other company related rules currently applicable in India and are subject to amendments and adjustments from time to time. In all matter including those not specifically covered here such as online version of this software, etc. will be governed by the rules of the company as shall be in force from time to time.</li>
</ul>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default TermsConditions
