import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'

function OurTeam() {
    return (

        <>
            <div class="wrapper">
                <Header />
                <section class="page-title-layout1 page-title-light bg-overlay bg-parallax text-center bg-img" style={{ backgroundImage: `url("assets/images/page-titles/1.jpg")`, backgroundSize: "cover", backgroundPosition: "center center" }}>
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 offset-xl-3">
                                <h1 class="pagetitle__heading">Our Team</h1>
                                <nav>
                                    <ol class="breadcrumb justify-content-center mb-0">
                                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                                        {/* <li class="breadcrumb-item"><a href="about-us.html">Company</a></li> */}
                                        <li class="breadcrumb-item active" aria-current="page">Our Team</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
                <section >
                    <div className="container">
                    <p>Rocket Paisa is a one stop destination for all online services like Aadhaar Enabled Payment System (AePS), Mobile, DTH and Data Card Recharges, Postpaid Bill Payment, Electricty Bill Payment, Landline Bill Payment, Entertainment, Remittance / Money-transfers, PAN card Services. Rocket Paisa helps to connect the rural, urban and semi-urban towns and villages in India through its portal for all financial and non-financial services. 
                        Our strength in the fields of service, distribution and payment processing enables the centers to provide various online services at ease and economically. Rocket Paisa aims at providing consumers this convenience by creating a franchise network in every village, town and city. These franchisees are equipped with user friendly terminals, which are connected to the service provider's servers and execute transactions on a real-time basis.</p>
                    </div>
                </section>
                <Footer />
            </div>
        </>

    )
}

export default OurTeam
