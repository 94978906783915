import axios from 'axios';
import constant from './constant';

const getpagesdataUrl = "getpagesdata"
const settingdataUrl = "settingdata"
const contactusleadsUrl = "contactusleads"
const getStateDataUrl  = "register/getStatedata"
const getcitybystateUrl = 'register/getcitybystate'
const registerUrl = 'register/registernew'

const client = axios.create({
    baseURL: constant.API_URL,

  });
const client2 = axios.create({
  baseURL: constant.REGISTER_API_URL

  });
  export class ApiService {

    getpagesdataPostRequest(request) {
      return client.post(getpagesdataUrl, request)
    }
    settingdataGetRequest() {
      return client.get(settingdataUrl)
    }
    contactusleadsPostRequest(request) {
      return client.post(contactusleadsUrl, request)
    }
    getStateDataGetRequest() {
      return client2.get(getStateDataUrl)
    }
    getcitybystatePostRequest(request) {
      return client2.post(getcitybystateUrl, request)
    }
    
    registerPostRequest(request) {
      return client2.post(registerUrl, request)
    }
    
  }