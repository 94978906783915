import React, { useEffect, useRef, useState } from "react"
import { ApiService } from '../../Components/Services/apiservices';


function Footer(){

    return(
        <>
          <footer className="footer">
      <div className="footer-primary">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-3">
              <div className="footer-widget-contact">
                <h6 className="footer-widget__title">Quick Contacts</h6>
                <ul className="contact-list list-unstyled">
                  <li>If you have any questions or need help, feel free to contact with our team.</li>
                  <li>
                    <a href="tel:+91-9358190092" className="phone__number">
                      <i className="icon-phone"></i> <span><a href="tel:+91-9358190092">+91-9358190092</a></span>
                    </a>
                  </li>
                  <li>A-1 Parivahan Nagar Khatipura Road Jaipur-302012</li>
                </ul>
                {/* <a href="https://goo.gl/maps/MSZpAt4YREeqtFV66" target="_blank" className="btn btn__white btn__a mr-30">
                  <i className="fas fa-map-marker-alt"></i> <span>Get Directions</span>
                </a> */}
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-2">
              <div className="footer-widget-nav">
                <h6 className="footer-widget__title">Quick Links</h6>
                <nav>
                  <ul className="list-unstyled">
                    <li><a href="/our-team">Our Team</a></li>
                    <li><a href="/career">Career</a></li>
                    </ul>
                </nav>
              </div>
              <div className="footer-widget-nav">
                <h6 className="footer-widget__title">Useful Links</h6>
                <nav>
                  <ul className="list-unstyled">
                    <li><a href="/terms-conditions">Terms & Conditions</a></li>
                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                    <li><a href="/refunds-cancellations-policy">Refund and Cancellation</a></li>
                    </ul>
                </nav>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-2">
              <div className="footer-widget-nav">
                <h6 className="footer-widget__title">Our Services</h6>
                <nav>
                  <ul className="list-unstyled">
                    <li><a href="/aadhar-enabled-payment-system">Aadhar Enabled Payment System</a></li>
                    <li><a href="/prepaid-recharge">Prepaid Recharges</a></li>
                    <li><a href="/bill-payments-bbps">Bill Payments : BBPS</a></li>
                    <li><a href="/microatm">MicroATM</a></li>
                    <li><a href="/pan-card-center">PAN Card Center</a></li>
                    <li><a href="/aadhar-pay">Aadhar Pay</a></li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 offset-lg-1">
              <div className="footer-widget-newsletter">
                <img className="newsletter__stamp" src="/assets/images/icons/stamp.png" alt="stamp"/>
                <h6 className="newsletter__title">Sign up for industry alerts, deals, news and insights from Clanora .</h6>
                <form className="newsletter__form">
                  <input type="text" className="form-control" placeholder="Your Email Address"/>
                  <button className="btn btn__secondary">Subscribe</button>
                </form>
              </div>
              <div className="d-flex justify-content-end">
                <p className="fz-14">Have a question? <a href="#" className="underlined__a">Click here</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-2">
              <ul className="social-icons list-unstyled my-2">
              <li>
               
              <a href="https://www.facebook.com/people/Rocket-Paisa/100083749834731/" target="_blank"><i className="fab fa-facebook-f"></i></a>
                    </li>
                  <li>
                  <a href="https://www.instagram.com/rocketpaisa/" target="_blank"><i className="fab fa-instagram"></i></a>

                  </li>

                  <li>
                
                  <a href="https://twitter.com/Rocketpaisa" target="_blank"><i className="fab fa-twitter"></i></a>
                  </li>
              </ul>
            </div>
            <div className="col-sm-12 col-md-8 col-lg-8 col-xl-10 d-flex justify-content-between align-items-center">
              <div>
                <div className="footer__copyrights">
                  <span className="fz-14">&copy; 2023 RocketPaisa, All Rights Reserved. With Love by </span>
                  <a className="fz-14 color-primary" href="/">Rocketpaisa.in</a>
                </div>
              
              </div>
              <button id="scrollTopBtn">
                <i className="fas fa-long-arrow-alt-up"></i>
                <span className="scroll__text">Scroll To Top</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
        </>
    )
}
export default Footer