import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'

function AadharPay() {
    return (
        <>
            <div class="wrapper">
                <Header />
                <section class="page-title-layout1 page-title-light bg-overlay bg-parallax text-center bg-img" style={{ backgroundImage: `url("assets/images/page-titles/1.jpg")`, backgroundSize: "cover", backgroundPosition: "center center" }}>
                    {/* <div class="bg-img"><img src="assets/images/page-titles/1.jpg" alt="background"/></div> */}
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 offset-xl-3">
                                <h1 class="pagetitle__heading">Aadhar Pay</h1>
                                <nav>
                                    <ol class="breadcrumb justify-content-center mb-0">
                                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                                        {/* <li class="breadcrumb-item"><a href="about-us.html">Company</a></li> */}
                                        <li class="breadcrumb-item active" aria-current="page">Aadhar Pay</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                    <h1>Aadhaar Pay</h1>
<p>Aadhaar Pay is a secure and convenient payment system introduced by the Indian government to promote digital transactions. Rocket Paisa, a leading fintech company, has integrated Aadhaar Pay into its services, offering users a hassle-free and efficient payment experience. Let's explore the benefits, features, and steps to use Aadhaar Pay at Rocket Paisa in detail:</p>
<h2>Benefits of Aadhaar Pay at Rocket Paisa:</h2>
<ol>
<li>Convenience: Aadhaar Pay simplifies the payment process by enabling users to make transactions using their Aadhaar number linked to their bank account. It eliminates the need for physical cards or cash, making it a convenient option for users.</li>
<li>Financial Inclusion: Aadhaar Pay plays a vital role in promoting financial inclusion. It enables individuals without a bank account to receive government subsidies and benefits directly into their Aadhaar-linked bank account. This helps in reducing leakages and ensuring efficient delivery of welfare schemes.</li>
<li>Secure and Encrypted: Aadhaar Pay transactions are highly secure and encrypted, ensuring the safety of user data. It uses biometric authentication, including fingerprint or iris scan, to authenticate the user during the transaction, adding an extra layer of security.</li>
<li>Cost-Effective: Aadhaar Pay eliminates the need for point-of-sale (POS) devices or card swipe machines, reducing infrastructure costs for merchants and increasing the acceptance of digital payments. This makes it a cost-effective solution for small businesses and merchants.</li>
</ol>
<h2>Features of Aadhaar Pay at Rocket Paisa:</h2>
<ol>
<li>Biometric Authentication: Aadhaar Pay leverages biometric authentication, such as fingerprint or iris scan, to verify the identity of the user during the transaction. This ensures that only authorized individuals can make payments.</li>
<li>Aadhaar Integration: Rocket Paisa seamlessly integrates with the Aadhaar database, allowing users to link their Aadhaar number with their bank account. This enables quick and secure transactions using the Aadhaar Pay platform.</li>
<li>Multiple Payment Options: Aadhaar Pay supports various payment modes, including both online and offline transactions. Users can make payments through the Rocket Paisa app, QR codes, or using a unique payment ID generated by the system.</li>
<li>Merchant Acceptance: Rocket Paisa has a wide network of merchants who accept Aadhaar Pay as a payment method. This expands the scope of digital transactions and promotes cashless payments across various retail outlets, shops, and service providers.</li>
</ol>
<h2>How to Use Aadhaar Pay at Rocket Paisa:</h2>
<ol>
<li>Registration: To use Aadhaar Pay at Rocket Paisa, users need to download the Rocket Paisa mobile app from the respective app store and register with their Aadhaar number and other necessary details.</li>
<li>Link Bank Account: Once registered, users must link their Aadhaar number with their bank account. This can be done by providing the necessary details and completing the authentication process as per the app's instructions.</li>
<li>Transaction Process: To make a payment, the user needs to select the Aadhaar Pay option within the Rocket Paisa app. They will be prompted to enter the transaction amount and verify their identity using biometric authentication.</li>
<li>Complete the Transaction: After successful authentication, the user needs to authorize the payment by confirming the details displayed on the screen. The payment is then processed, and both the user and the merchant receive a confirmation of the transaction.</li>
<li>Transaction History and Receipts: Rocket Paisa provides users with access to their transaction history within the app. Users can view details of their previous transactions, generate receipts, and keep track of their expenditures conveniently.</li>
</ol>
<p>In conclusion, Aadhaar Pay at Rocket Paisa offers a secure, convenient, and inclusive payment solution that promotes digital transactions and financial empowerment. Its benefits include ease of use, enhanced security, and cost-effectiveness. By integrating with the Aadhaar system, Rocket Paisa enables users to make payments using their Aadhaar number, eliminating the need for physical cards or cash. This innovative payment system contributes to the government's push for a cashless economy and empowers individuals across various socioeconomic backgrounds to participate in digital transactions.</p>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default AadharPay
