import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { ApiService } from '../../Components/Services/apiservices';
let apiServices = new ApiService();

function Register(){
const [saveRegisterData ,setSaveRegisterData ] = useState({firstname:"",lastname:"",mobilenumber:"",emailid:"",shopnow:"",pincode:"",city:"",fulladdress:"",state:""})
const [stateData , setStateData] = useState([])
const [cityData , setCityData] = useState([])
const didMountRef = useRef(true)
useEffect(() => {
    if (didMountRef.current) {
        apiServices.getStateDataGetRequest().then(res => {
            if(res.data.status == "success"){
              setStateData(res.data.data)
            }
            
        })
   }
    didMountRef.current = false;
}, [])
    const handlechangedata = (e) => {
        const value = e.target.value;
        const key = e.target.name;
        setSaveRegisterData({ ...saveRegisterData, [key]: value })
    }

    const submitregister =()=>{
        document.getElementById("firstname").style.border = ""
        document.getElementById("lastname").style.border = ""
        document.getElementById("mobilenumber").style.border = ""
        document.getElementById("emailid").style.border = ""
        document.getElementById("shopnow").style.border = ""
        document.getElementById("pincode").style.border = ""
        document.getElementById("state").style.border = ""
        document.getElementById("city").style.border = ""
        document.getElementById("fulladdress").style.border = ""

        if(saveRegisterData.firstname == ""){
            document.getElementById("firstname").style.border = "1px solid red"
            return false
        }
        if(saveRegisterData.lastname == ""){
            document.getElementById("lastname").style.border = "1px solid red"
            return false
        }
        if(saveRegisterData.mobilenumber == "" || saveRegisterData.mobilenumber.length !== 10 ){
            document.getElementById("mobilenumber").style.border = "1px solid red"
            return false
        }
        if(saveRegisterData.emailid == ""){
            document.getElementById("emailid").style.border = "1px solid red"
            return false
        }
        if(saveRegisterData.shopnow == ""){
            document.getElementById("shopnow").style.border = "1px solid red"
            return false
        }
        if(saveRegisterData.pincode == ""){
            document.getElementById("pincode").style.border = "1px solid red"
            return false
        }
        if(saveRegisterData.state == ""){
            document.getElementById("state").style.border = "1px solid red"
            return false
        }
        if(saveRegisterData.city == ""){
            document.getElementById("city").style.border = "1px solid red"
            return false
        }
        if(saveRegisterData.fulladdress == ""){
            document.getElementById("fulladdress").style.border = "1px solid red"
            return false
        }
        const formData = new FormData();
        formData.append('user_first_name', saveRegisterData.firstname);
        formData.append('user_last_name', saveRegisterData.lastname);
        formData.append('user_mobile', saveRegisterData.mobilenumber);
        formData.append('user_email_id', saveRegisterData.emailid);
        formData.append('user_bussiness_name', saveRegisterData.shopnow);
        formData.append('user_pincode', saveRegisterData.pincode);
        formData.append('user_state_id', saveRegisterData.state);
        formData.append('user_city_id', saveRegisterData.city);
        formData.append('user_address', saveRegisterData.fulladdress);

    apiServices.registerPostRequest(formData).then(res=>{
        if(res.data.status == "success"){
            alert(res.data.message)
            window.location.reload()
        }
        else{
            alert(res.data.message)
            return false
        }

    })
   
    }
    const getcitydatabystate=(stateid)=>{
        const formData = new FormData();
        formData.append('state_id', stateid);
      apiServices.getcitybystatePostRequest(formData).then(res=>{
if(res.data.status == "success"){
    setCityData(res.data.data)
}
setSaveRegisterData({ ...saveRegisterData, ['state']: stateid })
      })  
    }

    return(
        <>
         <div className="wrapper">
        <Header/>
       
        <section className="contact-layout1 pt-0 mt--100" style={{marginTop:"20px"}}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="contact-panel d-flex flex-wrap">
            
                <div className="row">
                  <div className="col-sm-12">
                  
                    <h4 className="contact-panel__title">Partner With Us</h4>
              
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="First Name" id="firstname" name="firstname" onChange={handlechangedata}
                        />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Last Name" id="lastname" name="lastname" onChange={handlechangedata}
                        />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                      <input type="number" className="form-control" placeholder="Mobile Number" id="mobilenumber" name="mobilenumber" onChange={handlechangedata}
                        />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                      <input type="email" className="form-control" placeholder="Email ID" id="emailid" name="emailid" onChange={handlechangedata}
                        />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Shop Name" id="shopnow" name="shopnow" onChange={handlechangedata}
                        />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                      <input type="number" className="form-control" placeholder="Pin Code" id="pincode" name="pincode" onChange={handlechangedata}
                        />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                    <select class="form-control" name="state"  onChange={(e)=>getcitydatabystate(e.target.value)} id="state">
                        <option>Select State</option>
                       {stateData.map((value)=>( 
                        <option value={value.state_id}>{value.state_name}</option>))}
                        </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                    <select class="form-control" name="city"  onChange={handlechangedata} id="city">
                        <option>Select City</option>
                       {cityData.map((value)=>( 
                        <option value={value.cities_id}>{value.cities_name}</option>))}
                        </select>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Full Address" id="fulladdress" name="fulladdress" onChange={handlechangedata}
                        />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                  <button type="submit" onClick={submitregister}
                      class="btn btn__secondary mt-10">
                      <span>Submit Request</span> <i class="icon-arrow-right icon-outlined"></i>
                    </button>
                    </div>
                </div>
            
             
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer/>
    </div>
        </>
    )
}
export default Register