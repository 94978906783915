import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'

function PrivacyPolicy() {
    return (
        <>
            <div class="wrapper">
                <Header />
                <section class="page-title-layout1 page-title-light bg-overlay bg-parallax text-center bg-img" style={{ backgroundImage: `url("assets/images/page-titles/1.jpg")`, backgroundSize: "cover", backgroundPosition: "center center" }}>
                    {/* <div class="bg-img"><img src="assets/images/page-titles/1.jpg" alt="background"/></div> */}
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 offset-xl-3">
                                <h1 class="pagetitle__heading">Privacy Policy</h1>
                                <nav>
                                    <ol class="breadcrumb justify-content-center mb-0">
                                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                                        {/* <li class="breadcrumb-item"><a href="about-us.html">Company</a></li> */}
                                        <li class="breadcrumb-item active" aria-current="page">Privacy Policy</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                    <div class="content">
<div class="row justify-content-md-center">
<div class="col-lg-12">
<div class="card">
<div class="card-body">
<div style={{textAlign: "center"}}><img style={{width: "20%"}} src="https://www.rocketpaisa.in/webroot/img/uploads/theme/1637927086RocketPaisa.png" alt="" />
<h1 style={{marginTop: "30px", marginBottom: "30px", fontWeight: "600"}}>Privacy Policy</h1>
</div>
<p style={{fontSize: "14px", fontWeight: "400"}}>We<strong> CJL FINTECH SOLUTIONS</strong> Registered at A-1, Parivahan Nagar, Khatipura Road, Jaipur (Rajasthan) &ndash; 302012. At <strong>CJL FINTECH SOLUTIONS</strong> which is known as <strong>Rocket Paisa</strong>, we value your trust &amp; respect your privacy. Please read our privacy policy carefully before registration for it helps you to have a clear understanding on what, when and how information provided can be used by the company from time to time for its internal use and provide better customer services to our end users. This Privacy Policy sets out how Rocket Paisa&rsquo;s website/WAP site/ mobile application use and protects any information that you provide when you use our website/WAP site/ mobile applications for any transaction. We are committed to ensuring that information shared by you is protected and we assure you that it shall solely be used in accordance with this Privacy Policy.</p>
<h4>1. What personal information do we collect from the people who are registered with us?</h4>
<p style={{fontSize: "14px", fontWeight: "400"}}>Rocket Paisa registered users may be asked to enter their name, phone number &amp; KYC documents to help them with better user experience.</p>
<h4>2. When do we collect information?</h4>
<p style={{fontSize: "14px", fontWeight: "400"}}>Information of the user is collected at the time of registration with us.</p>
<h4>3. How do we use your information?</h4>
<p style={{fontSize: "14px", fontWeight: "400"}}>Information collected can be used when user makes a transaction, sign up for our newsletter and respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:</p>
<h6 style={{marginLeft: "15px"}}>3.1 To allow us to better service you in responding to your customer service requests.</h6>
<h6 style={{marginLeft: "15px"}}>3.2 To administer a contest, promotion, survey or other site feature.</h6>
<h6 style={{marginLeft: "15px"}}>3.3 To quickly process your transactions.</h6>
<h6 style={{marginLeft: "15px"}}>3.4 To ask for ratings and reviews of services or products.</h6>
<h6 style={{marginLeft: "15px"}}>3.5 For financial and identity checks, fraud prevention checks, anti-money laundering and credit checks.</h6>
<h6 style={{marginLeft: "15px"}}>3.6 To improve our internal customer training.</h6>
<h6 style={{marginLeft: "15px"}}>3.7 To send you offer based on your previous orders and interests.</h6>
<h6 style={{marginLeft: "15px"}}>3.8 To notify you about changes to our service(s).</h6>
<p>&nbsp;</p>
<h4>4. How do we protect your information?</h4>
<p style={{fontSize: "14px", fontWeight: "400"}}>Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible. We do not use Malware Scanning. Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology. We implement a variety of security measures when a user places an order enters, submits, or accesses their information to maintain the safety of your personal information.</p>
<h4>5. Do we use 'cookies'?</h4>
<p style={{fontSize: "14px", fontWeight: "400"}}>We may use cookies for providing speedy responses wherever possible to our users to ensure best walk through experience of our application.</p>
</div>
</div>
</div>
</div>
</div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default PrivacyPolicy
