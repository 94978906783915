import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'

function Api() {
    return (
        <>
            <div class="wrapper">
                <Header />
                <section class="page-title-layout1 page-title-light bg-overlay bg-parallax text-center bg-img" style={{ backgroundImage: `url("assets/images/page-titles/1.jpg")`, backgroundSize: "cover", backgroundPosition: "center center" }}>
                    {/* <div class="bg-img"><img src="assets/images/page-titles/1.jpg" alt="background"/></div> */}
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 offset-xl-3">
                                <h1 class="pagetitle__heading">Api</h1>
                                <nav>
                                    <ol class="breadcrumb justify-content-center mb-0">
                                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                                        {/* <li class="breadcrumb-item"><a href="about-us.html">Company</a></li> */}
                                        <li class="breadcrumb-item active" aria-current="page">Api</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                    <p>Api</p>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default Api
