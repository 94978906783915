import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { ApiService } from '../../Components/Services/apiservices';
let apiServices = new ApiService();

function Contact(){
    const [saveContactData , setSaveContactData] = useState({contactname:"" , contactemail:"" ,contactphone:"",services:"",contactmessage:""})
    const [successMessage , setSuccessMessage ] = useState("")
 
    const handlechangedata = (e) => {
        const value = e.target.value;
        const key = e.target.name;
        setSaveContactData({ ...saveContactData, [key]: value })
    }

    const saveallcontactdetails=()=>{
        console.log(saveContactData)
        if(saveContactData.contactname==""){
            document.getElementById("contactname").style.border="1px solid red"
            return false
        }
       else if(saveContactData.contactemail==""){
            document.getElementById("contactemail").style.border="1px solid red"
            return false
        }
        else if(saveContactData.contactphone==""){
            document.getElementById("contactphone").style.border="1px solid red"
            return false
        }
       else if(saveContactData.services==""){
            document.getElementById("services").style.border="1px solid red"
            return false
        }
        else if(saveContactData.contactmessage==""){
            document.getElementById("contactmessage").style.border="1px solid red"
            return false
        }

        const dataString= {
          "contact_name" : saveContactData.contactname ,
          "contact_email" : saveContactData.contactemail,
          "contact_mobile": saveContactData.contactphone,
          "contact_subject" : saveContactData.services,
          "contact_message": saveContactData.contactmessage
        }

        apiServices.contactusleadsPostRequest(dataString).then(res => {
          if(res.data.status == "success"){
            setSuccessMessage(res.data.data)
            setTimeout(() => {
              window.location.reload()
            }, 2000);
            
          }
        })

    }
    return(
        <>
         <div class="wrapper">
        <Header/>
        <section class="google-map py-0">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3556.9114783983287!2d75.75078827527122!3d26.938020659002696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db30cb6fb48ef%3A0xb1d659bf8b92a253!2sCJL%20FinTech%20Solutions%20(Rocket%20Paisa)!5e0!3m2!1sen!2sin!4v1687784695041!5m2!1sen!2sin" frameBorder={"0"} height={"620"} width={"100%"} style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      {/* <iframe frameborder="0" height="620" width="100%"
        src="https://maps.google.com/maps?q=Suite%20100%20San%20Francisco%2C%20LA%2094107%20United%20States&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near"></iframe> */}
    </section>
    <section class="contact-layout1 pt-0 mt--100">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="contact-panel d-flex flex-wrap">
              <div class="contact-panel__form" >
                <div class="row">
                  <div class="col-sm-12">
                    {successMessage != "" ?
                  <div class="alert alert-success" role="alert">
                           {successMessage}
                        </div>:""}
                    <h4 class="contact-panel__title">Get In Touch</h4>
                    <p class="contact-panel__desc mb-30">We take great pride in everything that we do, control over
                      products allows us to ensure customers receive the best quality service and highest standards, you
                      need a dedicated team of specialists.
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="Name" id="contactname" name="contactname" onChange={handlechangedata}
                        />
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <input type="email" class="form-control" placeholder="Email" id="contactemail" onChange={handlechangedata}
                        name="contactemail"/>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="Phone" id="contactphone" onChange={handlechangedata}
                        name="contactphone"/>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <select class="form-control" name="services"  onChange={handlechangedata} id="services">
                        <option value="0">Select Your services</option>
                        <option value="1">Aadhar Enabled Payment System</option>
                        <option value="2">Bill Payments : BBPS</option>
                        <option value="3">MicroATM</option>
                        <option value="4">PAN Card Center</option>
                        <option value="5">Aadhar Pay</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <textarea class="form-control" placeholder="Additional Details" id="contactmessage"  onChange={handlechangedata}
                        name="contactmessage"></textarea>
                    </div>
                    <button type="submit" onClick={saveallcontactdetails}
                      class="btn btn__secondary btn__block btn__xhight d-flex justify-content-between mt-10">
                      <span>Submit Request</span> <i class="icon-arrow-right icon-outlined"></i>
                    </button>
                    <div class="contact-result"></div>
                  </div>
                </div>
              </div>
              <div
                class="contact-panel__info d-flex flex-column justify-content-between bg-overlay bg-overlay-primary-gradient">
                <div>
                  <h3 class="contact-panel__subtitle color-white">Homeowners Like Us!</h3>
                  <h4 class="contact-panel__title color-white">Healthy Environment For Your Family</h4>
                  <p class="contact-panel__desc font-weight-bold color-white mb-30">The processes and systems we put in
                    place provide high quality service with a focus on safety.
                  </p>
                </div>
                <div>
                  <ul class="contact__list list-unstyled mb-0">
                    <li>
                      <i class="icon-phone"></i><a href="tel:+5565454117">Emergency Line: +91-9358190092</a>
                    </li>
                    <li>
                      <i class="icon-location"></i><a href="#">Location: A-1 Parivahan Nagar Khatipura Road Jaipur-302012</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
        <Footer/>
        </div>
        </>
    )
}
export default Contact