import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'

function RefundCancellation() {
    return (
        <>
            <div class="wrapper">
                <Header />
                <section class="page-title-layout1 page-title-light bg-overlay bg-parallax text-center bg-img" style={{ backgroundImage: `url("assets/images/page-titles/1.jpg")`, backgroundSize: "cover", backgroundPosition: "center center" }}>
                    {/* <div class="bg-img"><img src="assets/images/page-titles/1.jpg" alt="background"/></div> */}
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 offset-xl-3">
                                <h1 class="pagetitle__heading">Refund and Cancellation Policy</h1>
                                <nav>
                                    <ol class="breadcrumb justify-content-center mb-0">
                                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                                        {/* <li class="breadcrumb-item"><a href="about-us.html">Company</a></li> */}
                                        <li class="breadcrumb-item active" aria-current="page">Refund and Cancellation Policy</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                    <div class="content">
<div class="row justify-content-md-center">
<div class="col-lg-12">
<div class="card">
<div class="card-body">
<div style={{textAlign: "center"}}><img style={{width: "20%"}} src="https://www.rocketpaisa.in/webroot/img/uploads/theme/1637927086RocketPaisa.png" alt="" />
<h1 style={{marginTop: "30px", marginBottom: "30px", fontWeight: "600"}}>Refunds/Cancellations Policy</h1>
</div>
<p style={{fontSize: "14px", fontWeight: "400"}}>Welcome to <strong>CJL FINTECH SOLUTIONS</strong>. Our focus is complete customer satisfaction. Thanks for using our services (&ldquo;Services&rdquo;). The Services are provided by <strong>CJL FINTECH SOLUTIONS</strong>, located A-1, Parivahan Nagar,Khatipura Road, Jaipur (Rajasthan) &ndash; 302012 India. Which is also known as <strong>Rocket Paisa</strong>.</p>
<p style={{fontSize: "14px", fontWeight: "400"}}>A Sale is final sale as it has executed from your login panel which is provided by Rocket Paisa and payment has been deducted for such sale. As a sale is done anytime by your login panel, no refund or exchange or cancellation will be permitted. You and only you are responsible for information provided by you for purchase.</p>
<p style={{fontSize: "14px", fontWeight: "400"}}>Rocket Paisa is not responsible for any purchase by incorrect information provided by customer. So it is only you who needs to be cautious before placing any order. You are responsible for the mobile number provided /entered by you or DTH account number by which you purchased the prepaid recharge and all charges that result from those purchases.</p>
<p style={{fontSize: "14px", fontWeight: "400"}}>Rocket Paisa is not responsible for any purchase of prepaid recharge for an incorrect mobile number or DTH account number or incorrect toll or data card information. If a transaction performed by you on login panel, money has been charged by your card or bank account and a recharge is not delivered within 24 hours of completion of your transaction then you may inform us by sending an email to our customer service help.rocketpaisa@gmail.com.</p>
<p style={{fontSize: "14px", fontWeight: "400"}}>Rocket Paisa shall investigate that incident and if it is found that money was charged by your wallet or bank account without delivery of the recharge then money will be refunded to you within 7 working days from the date of receipt of your email. All refunds will be credited to your Rocket Paisa Wallet account. If the service is provided by third party and it is a case of refund then it shall be processed as per time line given by third party and you are bounded by the terms and conditions of refund or refund policy of third party .IT services given /executed once cannot be taken back similarly any alteration/amendment in those will result in additional charges. Charges for services provided by third party entirely depend on those parties only. If such parties alter charges for then they will be applied without prior notice.</p>
<p style={{fontSize: "14px", fontWeight: "400"}}>No interest will be payable on Rocket Paisa Wallet on the available balance value /amount/ payment reflected on the Rocket Paisa Wallet. In case we receive a cancellation notice for a service order from you within 24-Hrs of placing the order and if the order has not been processed by us, we will be happy to cancel the order and refund the entire amount to you within 7 business days. We will not be able to cancel those orders that have already been processed by us.</p>
<h4><strong>1. Who can get a refund?</strong></h4>
<p style={{fontSize: "14px", fontWeight: "400"}}>Rocket Paisa refunds if payment is successfully credited to Rocket Paisa Wallet balance but user is not able to avail services.</p>
<h4><strong>2. How to request a refund?</strong></h4>
<p style={{fontSize: "14px", fontWeight: "400"}}>Mail us <strong>help.rocketpaisa@gmail.com</strong> with mentioning correct information of problem which you are facing to availing services in your login panel.</p>
<h4><strong>3. Refund Policy for Franchisee Fees:</strong></h4>
<p style={{fontSize: "14px", fontWeight: "400"}}>Once you pay the Rocket Paisa a fee for franchisee and we register you as a franchisee then we will not refund franchisee fee in any circumstances. If you register yourself with Rocket Paisa but somehow or by any means if you do not avail services, then Rocket Paisa is not at all responsible for refund. An amount paid once paid for Product/panel never refund in any case. If you paid us and you are not registered on Rocket Paisa panel then you can only ask for refund by mailing us <strong>help.rocketpaisa@gmail.com.</strong></p>
<h4><strong>4. Changes:</strong></h4>
<p style={{fontSize: "14px", fontWeight: "400"}}>Rocket Paisa may changes policies at any time, without prior notice under its sole discretion; amend these policies from time to time. You are therefore requested to review these policies periodically. Your continued use Rocket Paisa website after any such amendments automatically implies your acceptance of the same thereof.</p>
<h4><strong>5. Contact us regarding our refund policy:</strong></h4>
<p style={{fontSize: "14px", fontWeight: "400"}}>If you have queries or suggestions regarding our refund policies kindly E&ndash;mail us at <strong>help.rocketpaisa@gmail.com</strong>.</p>
</div>
</div>
</div>
</div>
</div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default RefundCancellation
