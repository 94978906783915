import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'

function MicroAtm() {
    return (
        <>
            <div class="wrapper">
                <Header />
                <section class="page-title-layout1 page-title-light bg-overlay bg-parallax text-center bg-img" style={{ backgroundImage: `url("assets/images/page-titles/1.jpg")`, backgroundSize: "cover", backgroundPosition: "center center" }}>
                    {/* <div class="bg-img"><img src="assets/images/page-titles/1.jpg" alt="background"/></div> */}
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 offset-xl-3">
                                <h1 class="pagetitle__heading">Micro Atm</h1>
                                <nav>
                                    <ol class="breadcrumb justify-content-center mb-0">
                                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                                        {/* <li class="breadcrumb-item"><a href="about-us.html">Company</a></li> */}
                                        <li class="breadcrumb-item active" aria-current="page">Micro Atm</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="">
                    <div className="container">
                    <h1>MicroATM</h1>
<ul>
<li>MicroATM or MiniATM is a financial service offered by Rocket Paisa, a leading digital payment and financial technology company.</li>
<li>It is a compact, portable device that enables individuals to perform basic banking and financial transactions in areas where traditional banking infrastructure is limited or inaccessible.</li>
</ul>
<h2>Accessibility and Convenience:</h2>
<ul>
<li>MicroATMs are designed to bring banking services closer to the masses, especially in rural and remote areas.</li>
<li>They are equipped with GPRS or wireless connectivity, allowing transactions to be processed in real time.</li>
<li>These devices can be set up at various locations such as retail shops, post offices, and mobile vans, providing convenient access to financial services.</li>
</ul>
<h2>Services Offered:</h2>
<ul>
<li>Cash Withdrawal: Users can withdraw cash using their debit cards, allowing them to access funds even if there are no nearby ATMs.</li>
<li>Balance Inquiry: MicroATMs provide the option to check account balances, enabling users to stay updated on their finances.</li>
<li>Fund Transfer: Individuals can transfer money between bank accounts, facilitating peer-to-peer transactions or remittances.</li>
<li>Aadhaar-enabled Payments: MicroATMs can authenticate users through their Aadhaar biometric data, enabling cashless transactions without the need for physical cards.</li>
<li>Bill Payments and Recharges: Users can pay utility bills, recharge mobile phones, and avail of other similar services through the MicroATM platform.</li>
</ul>
<h2>User Verification and Security:</h2>
<ul>
<li>MicroATMs incorporate robust security measures to protect user data and transactions.</li>
<li>They employ biometric authentication, including fingerprints and iris scans, to verify the identity of users.</li>
<li>PIN-based authentication is also available for added security during transactions.</li>
<li>Encryption protocols and secure communication channels ensure the privacy and integrity of sensitive information.</li>
</ul>
<h2>Transaction Process:</h2>
<ol>
<li>A user initiates a transaction by inserting their debit card or providing their Aadhaar number for authentication.</li>
<li>The device connects to the banking network through a secure channel and sends the transaction request.</li>
<li>The user verifies the transaction amount and enters their PIN or provides biometric data for authentication.</li>
<li>The MicroATM communicates with the bank's server to process the transaction, and a confirmation message is displayed on the device upon successful completion.</li>
</ol>
<h2>Benefits of MicroATM at Rocket Paisa:</h2>
<ul>
<li>Financial Inclusion: MicroATMs play a crucial role in expanding financial inclusion by reaching underbanked and unbanked populations.</li>
<li>Convenience and Accessibility: These devices offer banking services at the doorstep of users, reducing the need to travel long distances to access traditional banking services.</li>
<li>Cost-Effective: MicroATMs minimize infrastructure costs for banks and bring down transaction costs for customers, making financial services more affordable.</li>
<li>Enhanced Security: The implementation of biometric authentication and secure communication channels ensures secure transactions, reducing the risk of fraud.</li>
<li>Digital India Initiative: MicroATMs align with the government's vision of a digitally empowered society by promoting digital transactions and financial literacy.</li>
</ul>
<p>In conclusion, MicroATM or MiniATM at Rocket Paisa is a portable, user-friendly device that extends banking and financial services to underserved areas. By offering essential financial services like cash withdrawal, balance inquiry, fund transfer, and bill payments, MicroATMs empower individuals, promote financial inclusion, and contribute to the growth of a digitally inclusive economy.</p>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default MicroAtm
