import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { ApiService } from '../../Components/Services/apiservices';
let apiServices = new ApiService();
function Pages() {
    const { slug } = useParams()
    
    const [pagesData, setPagesData] = useState("")
    const didMountRef = useRef(true)
    // useEffect(() => {
    //     if (didMountRef.current) {
    //         const dataString = {
    //             "slug": slug
    //         }
    //         apiServices.getpagesdataPostRequest(dataString).then(res => {
    //             setPagesData(res.data.data)
    //         })
    //    }
    //     didMountRef.current = false;
    // }, [])
    return (
        <>
            <div class="wrapper">
                <Header />
                <section class="page-title-layout1 page-title-light bg-overlay bg-parallax text-center bg-img" style={{backgroundImage: `url("assets/images/page-titles/1.jpg")` , backgroundSize: "cover", backgroundPosition: "center center"}}>
      {/* <div class="bg-img"><img src="assets/images/page-titles/1.jpg" alt="background"/></div> */}
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 offset-xl-3">
            <h1 class="pagetitle__heading">{pagesData.page_name}</h1>
            <nav>
              <ol class="breadcrumb justify-content-center mb-0">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item"><a href="about-us.html">Company</a></li>
                <li class="breadcrumb-item active" aria-current="page">{pagesData.page_name}</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
                <section className="">
                    <div className="container">
                        {pagesData && pagesData.page_content != null ? <p dangerouslySetInnerHTML={{ __html: pagesData.page_content }}></p> : ""}
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}
export default Pages