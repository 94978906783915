import React, { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { ApiService } from '../../Components/Services/apiservices';
let apiServices = new ApiService();

function Header(){
  const location = useLocation()
  const didMountRef = useRef(true)
  const [showMenuBar , setShowMenuBar] = useState()
  const [settingData , setSettingData] =useState("")
//   useEffect(() => {
//     if (didMountRef.current) {
      
//         apiServices.settingdataGetRequest().then(res => {
//           setSettingData(res.data.data)
//         })
//    }
//     didMountRef.current = false;
// }, [])

const openmenubar =()=>{
  setShowMenuBar(true)
}
const closemenubar=()=>{
  setShowMenuBar(false)
}

    return(
        <>
           <header className="header header-layout1">
      <div className="header-topbar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 d-flex align-items-center justify-content-between">
              <span className="topbar__text">
                <span className="colored__text"><i className="icon-alert"></i> Enjoy Our Hot Offers!</span>
                <span className="ml-2">Call :<a href="tel:+91-9358190092">+91-9358190092</a></span>
              </span>
              <div className="d-flex">
                <ul className="contact__list d-flex flex-wrap align-items-center list-unstyled mb-0">
                  <li>
                    <i className="icon-email"></i>
                    <a href="#">
                      <span>Email: </span> <span><a href="mailto: rocketpaisa@gmail.com">rocketpaisa@gmail.com</a></span>
                    </a>
                  </li>
            
                </ul>
                <ul className="social-icons list-unstyled mb-0 ml-30">                               
                  <li>
                    
                    <a href="https://www.facebook.com/people/Rocket-Paisa/100083749834731/" target="_blank"><i className="fab fa-facebook-f"></i></a>
                    </li>
                  <li>
                    <a href="https://www.instagram.com/rocketpaisa/" target="_blank"><i className="fab fa-instagram"></i></a>
                  </li>

                  <li>
                    <a href="https://twitter.com/Rocketpaisa" target="_blank"><i className="fab fa-twitter"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg sticky-navbar">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src="/assets/images/RocketPaisa_logo.png" className="logo-light" alt="logo" height="60px"/>
            <img src="/assets/images/RocketPaisa_logo.png" className="logo-dark" alt="logo" height="60px"/>
          </a>
          <button className="navbar-toggler" type="button" onClick={openmenubar}>
            <span className="menu-lines"><span></span></span>
          </button>
          <div className={showMenuBar == true ?"collapse navbar-collapse menu-opened":"collapse navbar-collapse"} id="mainNavigation">
            <ul className="navbar-nav mx-auto">
              <li className="nav__item has-dropdown">
                <a href="/" className={location.pathname == "/" ?"nav__item-link active":"nav__item-link"}>Home</a>
              </li>
              <li className="nav__item has-dropdown">
                <a href="#" data-toggle="dropdown" className={location.pathname == "/our-team" || location.pathname == "/career" ?"dropdown-toggle nav__item-link active":"dropdown-toggle nav__item-link"}>About Us</a>
                <ul className="dropdown-menu">
                  <li className="nav__item">
                    <a href="/our-team" className="nav__item-link">Our Team</a>
                  </li>
                  <li className="nav__item">
                    <a href="/career" className="nav__item-link">Career</a>
                  </li>
                </ul>
              </li>
              
              <li className="nav__item has-dropdown">
                <a href="#" data-toggle="dropdown" className={location.pathname=="/domestic-money-transfer"||location.pathname=="/aadhar-enabled-payment-system"||location.pathname=="prepaid-cards"||location.pathname=="/prepaid-recharges"||location.pathname=="/bill-payments-bbps"||location.pathname=="/travel-stay"||location.pathname=="/fastag"||location.pathname=="/safegold"||location.pathname=="/microatm"||location.pathname=="/pan-card-center"||location.pathname=="/aadhar-pay"||location.pathname=="lic-premium-payment-portal" ?"dropdown-toggle nav__item-link active":"dropdown-toggle nav__item-link"}>Product & Services</a>
                <ul className="dropdown-menu">
                  <li className="nav__item">
                    <a href="/aadhar-enabled-payment-system" className="nav__item-link">Aadhar Enabled Payment System</a>
                  </li>
                  <li className="nav__item">
                    <a href="/prepaid-recharge" className="nav__item-link">Prepaid Recharges</a>
                  </li>
                  <li className="nav__item">
                    <a href="/bill-payments-bbps" className="nav__item-link">Bill Payments : BBPS</a>
                  </li>
                  <li className="nav__item">
                    <a href="/microatm" className="nav__item-link">MicroATM</a>
                  </li>
                  <li className="nav__item">
                    <a href="/pan-card-center" className="nav__item-link">PAN Card Center</a>
                  </li>
                  <li className="nav__item">
                    <a href="/aadhar-pay" className="nav__item-link">Aadhar Pay</a>
                  </li>                 
                </ul>
              </li>
              <li className="nav__item has-dropdown">
                <a href="#" data-toggle="dropdown" className={location.pathname == "/retailer"||location.pathname == "/distributor"||location.pathname == "/api"?"dropdown-toggle nav__item-link active":"dropdown-toggle nav__item-link"}>Channel Partner</a>
                <ul className="dropdown-menu">
                  <li className="nav__item">
                    <a href="/retailer" className="nav__item-link">Retailer</a>
                  </li>
                  <li className="nav__item">
                    <a href="/distributor" className="nav__item-link">Distributor</a>
                  </li>
                  <li className="nav__item">
                    <a href="/api" className="nav__item-link">API</a>
                  </li>
                </ul>
              </li>
              
              <li className="nav__item">
                <a href="/faqs" className={location.pathname == "/faqs" ?"nav__item-link active":"nav__item-link"}>Faqs</a>
              </li>
              <li className="nav__item">
                <a href="/contact-us" className={location.pathname == "/contact-us" ?"nav__item-link active":"nav__item-link"}>Contact Us</a>
              </li>
            </ul>
           
            <button className="close-mobile-menu d-block d-lg-none"><i className="fas fa-times" onClick={closemenubar}></i></button>
          </div>
          <ul className="navbar-actions d-none d-xl-flex align-items-center list-unstyled mb-0">
           
            <li><a href="https://login.rocketpaisa.in/" target="new" className="btn btn__primary action__btn-request" style={{marginRight:"12px"}}>Login</a>
            <a href="/register" target="new" className="btn btn__primary action__btn-request">Register</a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
        </>
    )
}
export default Header