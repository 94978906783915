import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css'

function Home(){
    return(
        <>
        <div class="wrapper">
      <Header/>
     
    <section className="about-layout1 pt-130 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-7 col-xl-6">
            <div className="row">
              <div className="col-12">
                <div className="heading-layout2">
                  <h3 className="heading__title mb-40"> Earn Like a Rocket with RocketPaisa - Quick and Effortless Money-Making</h3>
                </div>
              </div>
             
              <div className="col-sm-12 col-md-12">
                <div className="about__Text">
                  <p className="mb-20">Welcome to Rocket Paisa, your ultimate destination for easy earning! We believe that making money should be hassle-free and exciting, just like a rocket soaring through the sky.</p>
                  <div className="d-flex align-items-center mb-40">
                    <a href="/contact-us" className="btn btn__primary mr-30">
                      <span>Contact Us</span>
                      <i className="icon-arrow-right ml-20"></i>
                    </a>
                 
                  </div>
                </div>
                <ul className="list-items list-items-layout2 list-horizontal list-unstyled">
                  <li>Making Your Financial Life Easier</li>
                  <li>Empowering You with Financial Freedom</li>
                  <li>Quick and convenient transactions</li>
                  <li>User-friendly interface for easy navigation</li>
                  <li>Offers a wide range of financial services</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5 offset-xl-1">
            <div className="about__img">
              <img src="/assets/images/homepagefirstsection.jpg" alt="about"/>
              <div className="rating">
                <div className="rating__icon">
                  <img src="/assets/images/icons/stars.png" alt="stars"/>
                </div>
                <div className="rating__text">
                  <span className="rating__percentage">97%</span>
                  <span>Customer Rating</span>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="services-layout1 services-carousel pt-130 pb-60">
  
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
            <div className="heading text-center mb-60">
              <h2 className="heading__subtitle">Our Services</h2>
              <h3 className="heading__title">Specialist Disinfection Services That Fits Your Premises</h3>
            </div>
          </div>        </div>
        <div className="row">
          <div className="col-12">
           
            <Swiper
              breakpoints={{
                // when window width is >= 640px
                340: {
                    width: 340,
                    slidesPerView: 1,
                },
                640: {
                    width: 640,
                    slidesPerView:2,
                },
                // when window width is >= 768px
                768: {
                    width: 768,
                    slidesPerView: 2,
                },
                1000: {
                    width: 1000,
                    slidesPerView: 3,
                    spaceBetween: 20
                },
                1200: {
                  width: 1200,
                  slidesPerView:3,
                  spaceBetween: 20
              },
            }}>
              <SwiperSlide>
              <div className="service-item">
                <div className="service__overlay">
                  <div className="bg-img">
                    <img src="/assets/images/AadhaarEnabledPaymentSystembgimage.jpg" alt="service"/>
                  </div>
                </div>
                <div className="service__body">
                  <div className="service__icon">
                   <img src="/assets/images/aadharenabledpaymentsystem.png" />
                  </div>
                  <h4 className="service__title">Aadhar Enabled Payment System</h4>
                  <p className="service__desc">The Aadhaar Enabled Payment System (AEPS) is a digital payment 
platform introduced in India. It leverages the unique identification 
number, known as Aadhaar, to enable secure and convenient 
transactions.
                  </p>
                  <a href="/aadhar-enabled-payment-system" className="btn btn__secondary btn__block d-flex justify-content-between">
                    <span>Read More</span>
                    <i className="icon-arrow-right icon-outlined"></i>
                  </a>
                </div>
              </div>
              </SwiperSlide>
<SwiperSlide>
<div className="service-item">
                <div className="service__overlay">
                  <div className="bg-img">
                    <img src="/assets/images/prepaidrechargbgimage.jpg" alt="service"/>
                  </div>
                </div>
                <div className="service__body">
                  <div className="service__icon">
                    <img src="/assets/images/prepaidrecharge.png"/>
                  </div>
                  <h4 className="service__title">Prepaid Recharges</h4>
                  <p className="service__desc">Prepaid recharges are popular for their affordability, ease of use, and the
ability to manage expenses by monitoring usage.
                  </p>
                  <a href="/prepaid-recharge" className="btn btn__secondary btn__block d-flex justify-content-between">
                    <span>Read More</span>
                    <i className="icon-arrow-right icon-outlined"></i>
                  </a>
                </div>
              </div>
</SwiperSlide>
<SwiperSlide>
<div className="service-item">
                <div className="service__overlay">
                  <div className="bg-img">
                    <img src="/assets/images/billpaymentsbgimage.jpg" alt="service"/>
                  </div>
                </div>
                <div className="service__body">
                  <div className="service__icon">
                  <img src="/assets/images/billpaymentsbbps.png"/>
                  </div>
                  <h4 className="service__title">Bill Payments : BBPS</h4>
                  <p className="service__desc">BBPS simplifies the process by allowing individuals to make payments 
for electricity, water, gas, broadband, and other bills through a single 
interface.
                  </p>
                  <a href="bill-payments-bbps" className="btn btn__secondary btn__block d-flex justify-content-between">
                    <span>Read More</span>
                    <i className="icon-arrow-right icon-outlined"></i>
                  </a>
                </div>
              </div>
</SwiperSlide>
<SwiperSlide>  <div className="service-item">
                <div className="service__overlay">
                  <div className="bg-img">
                    <img src="/assets/images/microatmbgimage.png" alt="service"/>
                  </div>
                </div>
                <div className="service__body">
                  <div className="service__icon">
                    <img src="/assets/images/microatm.png"/>
                  </div>
                  <h4 className="service__title">MicroATM</h4>
                  <p className="service__desc">These devices function as miniaturized versions of traditional ATMs and 
allow customers to perform various banking transactions without visiting 
a bank branch.
                  </p>
                  <a href="microatm" className="btn btn__secondary btn__block d-flex justify-content-between">
                    <span>Read More</span>
                    <i className="icon-arrow-right icon-outlined"></i>
                  </a>
                </div>
              </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="service-item">
                <div className="service__overlay">
                  <div className="bg-img">
                    <img src="/assets/images/PANCardCenterbgimage.jpg" alt="service"/>
                  </div>
                </div>
                <div className="service__body">
                  <div className="service__icon">
                    <img src="/assets/images/PANCardCenter.png"/>
                  </div>
                  <h4 className="service__title">PAN Card Center</h4>
                  <p className="service__desc">The PAN card center serves as a one-stop destination for individuals to 
submit their PAN card applications, verify their documents, and complete
the necessary processes.
                  </p>
                  <a href="pan-card-center" className="btn btn__secondary btn__block d-flex justify-content-between">
                    <span>Read More</span>
                    <i className="icon-arrow-right icon-outlined"></i>
                  </a>
                </div>
              </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="service-item">
                <div className="service__overlay">
                  <div className="bg-img">
                    <img src="/assets/images/adharpaybgimage.jpg" alt="service"/>
                  </div>
                </div>
                <div className="service__body">
                  <div className="service__icon">
                  <img src="/assets/images/adharpay.png"/>
                  </div>
                  <h4 className="service__title">Aadhar Pay</h4>
                  <p className="service__desc">Aadhaar Pay is a digital payment system introduced by the Government 
of India that enables individuals to make transactions using their 
Aadhaar biometric information.
                  </p>
                  <a href="aadhar-pay" className="btn btn__secondary btn__block d-flex justify-content-between">
                    <span>Read More</span>
                    <i className="icon-arrow-right icon-outlined"></i>
                  </a>
                </div>
              </div>
          </SwiperSlide>           
            </Swiper>
          </div>
        </div>
      </div>
    </section>

    <section className="feature-layout1 feature-carousel bg-overlay bg-overlay-secondary-gradient pt-70 pb-50">
      <div className="container">
        <div className="row heading">
          <div className="col-12">
            <h2 className="heading__subtitle color-accent">See Why Homeowners Like Us!</h2>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-5">
            <h3 className="heading__title color-white">Rocket Paisa is your one-stop destination for all your financial and non-financial needs</h3>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 offset-xl-1">
            <p className="heading__desc font-weight-bold color-gray mb-30">With Rocket Paisa, you can easily navigate through the complex world of finance and make informed decisions to secure your financial future. Experience convenience, reliability, and efficiency with Rocket Paisa today.
            </p>
            
            <div className="d-flex flex-wrap">
              <a href="/our-team" className="btn btn__accent mr-30">
                <span>About Us</span>
                <i className="icon-arrow-right ml-20"></i>
              </a>
              <a href="/contact-us" className="btn btn__white btn__outlined">Contact Us</a>
            </div>
          </div>
        </div>
    
    
      </div>
    </section>
   
    
    <section className="work-process-layout1">
      
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
            <div className="heading text-center mb-40">
              <h2 className="heading__subtitle">Registration process</h2>
              <h3 className="heading__title">Register now and unlock a world of opportunities</h3>
            </div>
          </div>
        </div>
        <div className="row process-row">
         
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="process-item text-center">
              <div className="circle__icon"></div>
              <div className="check__icon">
                <i className="icon-checkmark"></i>
              </div>
              <h4 className="process__title">Registration Process</h4>
              <p className="process__desc">Upon registration, the user's details are sent to the admin, and the user receives their login credentials.</p>
              <div className="process__icon">
                {/* <i className="icon-mop"></i> */}
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="process-item text-center">
              <div className="circle__icon"></div>
              <div className="check__icon">
                <i className="icon-checkmark"></i>
              </div>
              <h4 className="process__title">Login Process</h4>
              <p className="process__desc">The user login and initiates the KYC process, where they upload all the required documents and submit the KYC application.</p>
              <div className="process__icon">
                {/* <i className="icon-sponge"></i> */}
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="process-item text-center">
              <div className="circle__icon"></div>
              <div className="check__icon">
                <i className="icon-checkmark"></i>
              </div>
              <h4 className="process__title">KYC approval</h4>
              <p className="process__desc">The user receives approval for their KYC application.</p>
              <div className="process__icon">
                {/* <i className="icon-tap"></i> */}
              </div>
            </div>
          </div>
          
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="process-item text-center">
              <div className="circle__icon"></div>
              <div className="check__icon">
                <i className="icon-checkmark"></i>
              </div>
              <h4 className="process__title">Enjoy</h4>
              <p className="process__desc">Once approved, the user can enjoy all the products and services available.</p>
              <div className="process__icon">
                {/* <i className="icon-vacuum-cleaner"></i> */}
              </div>
            </div>
          </div>
        </div>
   
      </div>
    </section>

    {/* <section className="clients pt-50 pb-50">
      <div className="container">
        <div className="row align-items-center">
          
          <div className="col-lg-12">
           
              <Swiper
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
                                         breakpoints={{
                                          // when window width is >= 640px
                                          340: {
                                              width: 340,
                                              slidesPerView: 3,
                                          },
                                          640: {
                                              width: 640,
                                              slidesPerView: 1.2,
                                          },
                                          // when window width is >= 768px
                                          768: {
                                              width: 768,
                                              slidesPerView: 2,
                                          },
                                          1000: {
                                              width: 1000,
                                              slidesPerView: 4.5,
                                              spaceBetween: 5
                                          },
                                      }}>
                <SwiperSlide>
                <div className="client">
                <img src="/assets/images/clients/1.png" alt="client"/>
                <img src="/assets/images/clients/1.png" alt="client"/>
              </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="client">
                <img src="/assets/images/clients/2.png" alt="client"/>
                <img src="/assets/images/clients/2.png" alt="client"/>
              </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="client">
                <img src="/assets/images/clients/3.png" alt="client"/>
                <img src="/assets/images/clients/3.png" alt="client"/>
              </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="client">
                <img src="/assets/images/clients/4.png" alt="client"/>
                <img src="/assets/images/clients/4.png" alt="client"/>
              </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="client">
                <img src="/assets/images/clients/5.png" alt="client"/>
                <img src="/assets/images/clients/5.png" alt="client"/>
              </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="client">
                <img src="/assets/images/clients/6.png" alt="client"/>
                <img src="/assets/images/clients/6.png" alt="client"/>
              </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="client">
                <img src="/assets/images/clients/7.png" alt="client"/>
                <img src="/assets/images/clients/7.png" alt="client"/>
              </div>
                </SwiperSlide>
              </Swiper>            
          </div>
        </div>
      </div>
    </section> */}
{/* 
    <section className="blog-grid pt-130 pb-60">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
            <div className="heading text-center mb-40">
              <h2 className="heading__subtitle">Recent Articles</h2>
              <h3 className="heading__title">Tips, News & Updates</h3>
            </div>
          </div>
        </div>
        <div className="row">
        
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="post-item">
              <div className="post__img">
                <a href="blog-single-post.html">
                  <img src="/assets/images/blog/grid/1.jpg" alt="post image" loading="lazy"/>
                </a>
              </div>
              <div className="post__body">
                <div className="post__meta d-flex">
                  <div className="post__meta-cat">
                    <a href="#">Cleaning</a>
                    <a href="#">Tips</a>
                    <a href="#">Tricks</a>
                  </div>
                  <span className="post__meta-date">Jan 30, 2022</span>
                </div>
                <h4 className="post__title">
                  <a href="blog-single-post.html">The Difference Between Bacterial and Viral Infections and How to
                    Avoid Them</a>
                </h4>
                <div className="post__meta-author mb-30">By <a href="#">John Ezak</a></div>
                <p className="post__desc">While bacterial infections and viral infections are extremely common, both come
                  with their own particular set of mild and severe risks to reduce your facility's risk of exposure...
                </p>
                <a href="blog-single-post.html" className="btn btn__secondary btn__a">
                  <i className="icon-arrow-right"></i>
                  <span>Read More</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="post-item">
              <div className="post__img">
                <a href="blog-single-post.html">
                  <img src="/assets/images/blog/grid/2.jpg" alt="post image" loading="lazy"/>
                </a>
              </div>
              <div className="post__body">
                <div className="post__meta d-flex">
                  <div className="post__meta-cat">
                    <a href="#">Cleaning</a>
                    <a href="#">Disinfecting</a>
                  </div>
                  <span className="post__meta-date">Jan 30, 2022</span>
                </div>
                <h4 className="post__title">
                  <a href="blog-single-post.html">Cleaning and Disinfecting Tips for Businesses Affected by
                    Coronavirus (COVID-19)</a>
                </h4>
                <div className="post__meta-author mb-30">By <a href="#">Karl Barry</a></div>
                <p className="post__desc">Current data suggests that SARS-CoV-2, the virus that causes COVID-19, spreads
                  through respiratory droplets produced when infected person speaks, coughs, or
                </p>
                <a href="blog-single-post.html" className="btn btn__secondary btn__a">
                  <i className="icon-arrow-right"></i>
                  <span>Read More</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="post-item">
              <div className="post__img">
                <a href="blog-single-post.html">
                  <img src="/assets/images/blog/grid/3.jpg" alt="post image" loading="lazy"/>
                </a>
              </div>
              <div className="post__body">
                <div className="post__meta d-flex">
                  <div className="post__meta-cat">
                    <a href="#">Commercial</a>
                    <a href="#">Cleaning</a>
                  </div>
                  <span className="post__meta-date">Jan 30, 2022</span>
                </div>
                <h4 className="post__title">
                  <a href="blog-single-post.html">Urethane Against Acrylic Treatments: Which is good for Your
                    Business</a>
                </h4>
                <div className="post__meta-author mb-30">By <a href="#">Sophia Barry</a></div>
                <p className="post__desc">Commercial flooring helps your business shine. Easy to clean, safe work surface,
                  and affordable, commercial flooring can last for years if taken care of properly...
                </p>
                <a href="blog-single-post.html" className="btn btn__secondary btn__a">
                  <i className="icon-arrow-right"></i>
                  <span>Read More</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}
    <Footer/>
    </div>
        </>
    )
}
export default Home